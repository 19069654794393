import MainLayout from '@/layouts/MainLayout';
import { login } from '@/services/users';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'Login',

  components: {
    MainLayout,
  },

  data() {
    return {
      email: null,
      password: null,
    };
  },

  validations: {
    email: {
      required: required,
    },
    password: {
      required: required,
    },
  },

  methods: {
    async onClickLogin() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const response = await login(this.email, this.password);

      if (!response) {
        return;
      }

      this.$router.push({ name: 'profile-editor' });
    },
  },
};
